import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import _ from "lodash"

import LayoutEn from "../components/layout.en"
import HeaderEn from "../components/header.en"
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo"

import * as indexStyle from "./index.module.css"

const IndexPage = () => {
  const data = useStaticQuery(graphql`{
  banner1: file(relativePath: {eq: "2xbanner1.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  default: file(relativePath: {eq: "default_works.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 720, quality: 80, layout: CONSTRAINED)
    }
  }
  works: allWpPost(
    filter: {categories: {nodes: {elemMatch: {slug: {eq: "works"}}}}}
    sort: {fields: date, order: DESC}
    limit: 4
  ) {
    edges {
      node {
        id
        slug
        title
        project {
          worksTitleCn
          worksTitleEn
          worksCover {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 720, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
  news: allWpPost(
    filter: {categories: {nodes: {elemMatch: {slug: {eq: "news"}}}}}
    sort: {fields: date, order: DESC}
    limit: 2
  ) {
    edges {
      node {
        id
        slug
        title
        date(formatString: "YYYY-MM-DD")
        news {
          newsTitleCn
          newsTitleEn
          newsCover {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 700, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
  home: wpPage(slug: {eq: "home"}) {
    id
    home {
      about {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 679, height: 757, layout: CONSTRAINED)
            }
          }
        }
      }
      clients {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
}
`)

  const [jsSideWaysText, setjsSideWaysText] = useState(" ")

  useEffect(() => {
    let jsSideWays = document.getElementsByClassName(
      "is-in-viewport js-sideway"
    )

    const addAnchor = () => {
      if (jsSideWays.length > 0) {
        setjsSideWaysText(jsSideWays[0].getAttribute("data-sr-only"))
      }
    }

    if (jsSideWays) {
      window.addEventListener("scroll", addAnchor, false)
    }
    return () => {
      window.removeEventListener("scroll", addAnchor)
    }
  }, [])

  const renderWorks = function () {
    const works = data.works.edges

    const result = works.map((work, index) => {
      const { id, slug, project } = work.node
      const { worksTitleEn } = project

      return (
        <li key={id}>
          <Link
            to={`/en/works/${slug}/`}
            className={`list01-item wow${
              index % 2 == 0 ? " fadeInLeft" : " fadeInRight"
            }`}
          >
            <h5 className="tit">{worksTitleEn}</h5>
            <div className="pic">
              <div className="img">
                {project?.worksCover ? (
                  <GatsbyImage image={project?.worksCover?.localFile?.childImageSharp?.gatsbyImageData} alt="" />
                ) : (
                  <GatsbyImage image={data.default?.childImageSharp?.gatsbyImageData} alt="" />
                )}
              </div>
            </div>
          </Link>
        </li>
      );
    })
    return result
  }

  const renderNewestNews = function () {
    const news = data.news.edges

    const result = news.map((newsItem, index) => {
      const { id, slug, news, date } = newsItem.node
      const { newsTitleEn } = news

      return (
        <li key={id}>
          {index === 0 ? (
            <div className="header-wrapper header-wrapper-en wow fadeInLeft">
              <div className="txt js-text-animation">Our most recent news.</div>
              <Link
                to="/news/"
                className="btn-right link-btn more-news js-text-animation"
              >
                see all our NEWS
              </Link>
            </div>
          ) : (
            ""
          )}
          <div className="item">
            <div
              className={`pic wow${
                index % 2 == 0 ? " fadeInRight" : " fadeInLeft"
              }`}
            >
              <Link to={`/en/news/${slug}/`}>
                <div className="img">
                  {news.newsCover ? (
                    <GatsbyImage image={news.newsCover?.localFile?.childImageSharp?.gatsbyImageData} alt="" />
                  ) : (
                    <GatsbyImage image={data.default?.childImageSharp?.gatsbyImageData} alt="" />
                  )}
                </div>
                <div>{date} ——</div>
                <div className="tit">{newsTitleEn.replace("<br />", "")}</div>
              </Link>
            </div>
          </div>
        </li>
      );
    })

    return result
  }

  return (
    <LayoutEn sitePage="home">
      <SEO title="home" />
      <div className={`mainContent`}>
        <HeaderEn
          activePage="home"
          color={{ bg: "#fff", text: "#000" }}
          classLists="home"
        />
        <div
          className="banner js-sideway"
          data-bg-color="#fff"
          data-color="#000"
          data-sr-only=""
        >
          <GatsbyImage image={data.banner1?.childImageSharp?.gatsbyImageData} alt="" />
          <div className="ban-txt">
            <p className="animate-sweet-in">
              <span className="js-text-animation animate-sweet-in">
                Integrity, Responsibility,Quality Excellence,
              </span>
              <br />
              <span className="js-text-animation animate-sweet-in">
                Collaboration & Innovation.
              </span>
            </p>
            <div className="pic animate-sweet-in">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 497.93 154.7"
              >
                <g>
                  <g>
                    <path d="M90.93,110.44c0-28.92-86.11-10.3-86.11-65.3C4.82,14.46,33.3,0,60.48,0c30.67,0,58.28,13.59,59.38,47.55H88.09C86.33,32,77.35,27,60.91,27c-9.2,0-22.13,4.38-22.13,15.55,0,26.52,86.12,7.45,86.12,64.2,0,35.06-31.34,48-61.79,48C30.24,154.7.22,138.48,0,102.11H33.3C34.18,119.86,48.2,128,64.42,128,74.72,128,90.93,123.81,90.93,110.44Z" />
                    <path d="M240.15,151.41,227,135c-11,10.52-24.55,18.41-40.1,18.41-26.52,0-48.43-18.63-48.43-45.8,0-16.87,8.55-28.92,21.91-38.35,2.63-2,5.26-3.5,7.89-5.47-7-7.89-12.27-16.88-12.27-27.61C156,14.25,172,0,193.48,0c20.38,0,37.25,13.37,37.25,34.62,0,15.56-10.74,25.2-22.13,33.75L229,93.13q7.89-8.88,15.12-18.41l19.06,19.5a211.74,211.74,0,0,1-16.65,20.38l29.8,36.81ZM185.59,84.8c-6.79,4.6-19.28,13.15-19.28,22.35,0,10.08,9.2,19.5,19.5,19.5,8.76,0,17.53-7.23,23.45-12.92Zm-2.63-50c0,6.36,5,10.74,8.55,15.12,4.82-3.28,12.27-7.89,12.27-14.68,0-6.57-4.6-9.86-10.52-9.86C188.22,25.42,183,28.93,183,34.84Z" />
                    <path d="M430.36,151.47h-39L360.47,47,329.35,151.47h-39l-28.92-149h36.15l17.53,102.76L344.25,2.47h32.21l29.15,102.76L423.13,2.47h36.16Z" />
                    <path d="M481,33.8a16.9,16.9,0,1,1,16.9-16.9A16.92,16.92,0,0,1,481,33.8Zm0-30.66A13.76,13.76,0,1,0,494.79,16.9,13.77,13.77,0,0,0,481,3.14Z" />
                    <path d="M486.26,26.5l-5-8.34h-3.71V26.5H475V6.86h6.46c4.18,0,7.18,1.42,7.18,5.52,0,3-1.85,4.87-4.66,5.52l5.28,8.6Zm-8.7-10.35H481c3.22,0,5-1.23,5-3.77s-1.77-3.51-5-3.51h-3.46Z" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div
          className="section1 js-sideway js-viewport-animation"
          data-bg-color="#c2f2ec"
          data-color="#000000"
          data-sr-only="PROJECT"
        >
          <ul className="list01">{renderWorks()}</ul>
          <Link to="/en/works/" className="btn-right link-btn wow fadeIn">
            see all our work
          </Link>
        </div>
        <div
          className="section2 js-sideway js-viewport-animation"
          data-bg-color="#fff"
          data-color="#000"
          data-sr-only="OUR CLIENT"
        >
          <div className="pic002 wow fadeIn">
            <GatsbyImage
              image={data.home.home.clients?.localFile?.childImageSharp?.gatsbyImageData} alt="" />
          </div>
        </div>
        <div
          className="section3 js-sideway js-viewport-animation"
          data-bg-color="#c2f2ec"
          data-color="#000"
          data-sr-only=""
        >
          <div className="marquee__wrapper marquee__wrapper-en wow fadeIn">
            <ul className="marquee__list">
              <li>PROJECT CONSULTANCY</li>
              <li>INTERIOR DESIGN</li>
              <li>PROJECT MANAGEMENT & CONSTRUCTION</li>
              <li>FACILITY MAINTENANCE</li>
            </ul>
            <ul className="marquee__list">
              <li>PROJECT CONSULTANCY</li>
              <li>INTERIOR DESIGN</li>
              <li>PROJECT MANAGEMENT & CONSTRUCTION</li>
              <li>FACILITY MAINTENANCE</li>
            </ul>
          </div>
          <div className="btn-center">
            <Link to="/en/services/" className="link-btn wow fadeIn">
              see all our services
            </Link>
          </div>
        </div>
        <div
          className="section4 js-sideway js-viewport-animation"
          data-bg-color="#fff"
          data-color="#000"
          data-sr-only="ABOUT"
        >
          <div className="wp">
            <div className="pic03 wow fadeInRight">
              <div className="pic">
                <div className="img">
                  <GatsbyImage
                    image={data.home.home.about.image?.localFile?.childImageSharp?.gatsbyImageData} alt="" />
                </div>
              </div>
              <div className="txt wow fadeInLeft">
                <div className="tit js-text-animation">
                  {new Date().getFullYear() - 2001} years, We are growing up
                  with you.
                </div>
                <Link
                  to="/en/about/"
                  className="link-btn link-uniform-font-size en-link-uniform"
                >
                  see all about
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section5 js-sideway js-viewport-animation"
          data-bg-color="#c2f2ec"
          data-color="#000"
          data-sr-only="NEWS"
        >
          <div className="wp">
            <ul className="list-pic01">{renderNewestNews()}</ul>
          </div>
        </div>
        <div className="sideway">
          <div className="sideway__inner">
            <span className="sideway__text">{jsSideWaysText}</span>
          </div>
        </div>
      </div>
    </LayoutEn>
  );
}

export default IndexPage
